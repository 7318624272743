import React from "react";
import '../style.css';

function Header() {

    return (
      

        
        <header class="d-flex justify-content-center py-3">
          <ul class="nav nav-pills">
            <li class="nav-item">
              <a href="/" class="nav-link <%= currentPage === 'home' ? 'active' : '' %>" >Home</a>
            </li>
            <li class="nav-item">
              <a href="/pricing" class="nav-link <%= currentPage === 'pricing' ? 'active' : '' %>">Pricing</a>
            </li>
            <li class="nav-item">
              <a href="/faqs" class="nav-link <%= currentPage === 'faqs' ? 'active' : '' %>">FAQs</a>
            </li>
            <li class="nav-item">
              <a href="/#contact" class="nav-link <%= currentPage === 'contact' ? 'active' : '' %>">Contact</a>
            </li>
          </ul>
         
        </header>

        
      
    )
}

export default Header;
