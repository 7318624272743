import './App.css';
// import './style.css';
import Header from './components/Header';
import Banner from './components/Banner';
import About from './components/About';
import Why from './components/Why';


function App() {
  return (
    <div>
      <Header />
      <Banner />

      <div class="grid_container">
     
      
        <About 
          title="About Us"
          blurb1="Experience unparalleled convenience with our reliable meet and greet car parking service. 
          Upon your arrival at the airport, our professional valet staff will be ready to greet you, assist with your luggage, 
          and securely park your vehicle in a safe location. When you return, your car will be waiting for you, ensuring a swift and hassle-free departure."
          blurb2="Trust us to take care of your parking needs, so you can focus on your journey ahead."
          contactInfo="Book Now Call 07454 042 685"
          imageSrc="/images/replacement-car-keys.png"
          imageAlt="car keys"
          reverseLayout={false}
        />
 
        <Why></Why>
 




      </div>
    </div>
  );
}

export default App;
