import React from "react";
import '../style.css';



function Banner() {

    return (

        <div className="orange-container">

          <div className="orange-flex">

            <div className="coname">
              <h1>ORANGE MEET & GREET</h1>

        
              <h2 className="sub"> Stansted Airport Parking</h2>
            </div>

            <div className="telnumber">
              <p className="call">call 07454 042 685</p>
              <p className="email">email enquiries@orangemeetandgreet.co.uk</p>
            </div>

          </div>  

        </div>  
    )
}

export default Banner;
