import React from "react";
import '../style.css';

function Why() {
    return (
        <div className="whyus_container">
            <div className="whyus_flex">
                <h2 className="title">Why You Should Trust Us</h2>

                <p>
                    As proud members of the Safer Parking Scheme, we adhere to the national 
                    standards designed to ensure the safety and security of your vehicle. <br></br>
                    Our car parks undergo rigorous evaluations by specially trained police assessors. 
                    Only those that meet the stringent criteria are awarded the prestigious Park Mark, 
                    a mark of excellence that signifies our commitment to providing a secure 
                    environment for your vehicle while you are away.
                </p>

                <p style={{ textAlign: "left" }}>
                    From the moment you hand over your keys to the moment you return, we prioritize your peace of mind. 
                    With a proven track record of excellent service and countless satisfied customers, you can trust us 
                    to take the utmost care of your vehicle.
                </p>

                <p className="booknow" style={{ textAlign: "left" }}>
                    Book Now Call 07454 042 685
                </p>  
            </div>   
        </div>
    );
}

export default Why;
